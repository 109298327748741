import { setString } from 'expo-clipboard';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { View } from 'react-native';
import { Button, Caption, Card, IconButton, List, Portal, Snackbar, Surface, Switch, useTheme, } from 'react-native-paper';
import { useAlphaBlendedColor } from 'use-alpha-blended-color';
import { AAA_THRESHOLD_CONTRAST, AA_THRESHOLD_CONTRAST, useContrast, } from 'use-color-luminance';
import { ColorPreview } from './ColorPreview';
function ContrastTest({ title, color, background, }) {
    const { colors: { error }, } = useTheme();
    const relativeContrast = useContrast(color, background);
    const passesAA = relativeContrast >= AA_THRESHOLD_CONTRAST;
    const passesAAA = relativeContrast >= AAA_THRESHOLD_CONTRAST;
    const icon = passesAAA ? 'check-all' : passesAA ? 'check' : 'close';
    return (React.createElement(List.Item, { title: title, left: (props) => (React.createElement(ColorPreview, { style: props.style, color: color, background: background })), right: (props) => (React.createElement(List.Icon, Object.assign({ icon: icon }, props, { color: passesAA ? props.color : error }))) }));
}
export function Accessibility() {
    const { colors: { primary, accent, surface, text, error, background }, } = useTheme();
    return (React.createElement(Fragment, null,
        React.createElement(List.Subheader, null, "Color Contrasts"),
        React.createElement(Surface, { style: { elevation: 1 } },
            React.createElement(ContrastTest, { title: "Accent on primary", color: accent, background: primary }),
            React.createElement(ContrastTest, { title: "Primary on surface", color: primary, background: surface }),
            React.createElement(ContrastTest, { title: "Accent on surface", color: accent, background: surface }),
            React.createElement(ContrastTest, { title: "Text on surface", color: text, background: surface }),
            React.createElement(ContrastTest, { title: "Error on surface", color: error, background: surface }),
            React.createElement(ContrastTest, { title: "Primary on background", color: primary, background: background }),
            React.createElement(ContrastTest, { title: "Accent on background", color: accent, background: background }),
            React.createElement(ContrastTest, { title: "Text on background", color: text, background: background }),
            React.createElement(ContrastTest, { title: "Error on background", color: error, background: background }),
            React.createElement(ContrastTest, { title: "White on primary", color: "#fff", background: primary }),
            React.createElement(ContrastTest, { title: "Black on primary", color: "#000", background: primary }),
            React.createElement(ContrastTest, { title: "White on accent", color: "#fff", background: accent }),
            React.createElement(ContrastTest, { title: "Black on accent", color: "#000", background: accent }))));
}
export function AccessibilityChecker() {
    const [useBackground, setUseBackground] = useState(false);
    const [showingSnackbar, setShowSnackbar] = useState(false);
    const snackbarContent = useRef('');
    const toggleUseBackground = useCallback(() => setUseBackground((prev) => !prev), [setUseBackground]);
    const showSnackbar = useCallback((text) => {
        setShowSnackbar(false);
        setTimeout(() => {
            snackbarContent.current = text;
            setShowSnackbar(true);
        }, 200);
    }, []);
    const { colors: { primary, surface, background }, } = useTheme();
    const [color, setColor] = useState(() => primary);
    const finalColorOnBackground = useAlphaBlendedColor(color, background);
    const finalColorOnSurface = useAlphaBlendedColor(color, surface);
    const contrastBackground = useContrast(finalColorOnBackground, background);
    const contrastSurface = useContrast(finalColorOnSurface, surface);
    const contrast = useBackground ? contrastBackground : contrastSurface;
    const onChangeColor = useCallback((result) => {
        const aa = Math.round((result.rgb.a === undefined ? 1 : result.rgb.a) * 255)
            .toString(16)
            .padStart(2, '0');
        setColor(result.hex + (aa === 'ff' ? '' : aa));
    }, [setColor]);
    return (React.createElement(Fragment, null,
        React.createElement(Card.Title, { title: "Contrast Checker" }),
        React.createElement(View, { style: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingHorizontal: 16,
            } },
            React.createElement(View, { style: { marginRight: 16, marginBottom: 12 } },
                React.createElement(SketchPicker, { color: color, onChange: onChangeColor, onChangeComplete: onChangeColor })),
            React.createElement(View, { style: {
                    marginBottom: 'auto',
                } },
                React.createElement(Card, { style: {
                        elevation: 4,
                        flex: 1,
                        minWidth: 220,
                        maxWidth: 220,
                        marginBottom: 12,
                    } },
                    React.createElement(Card.Content, null,
                        React.createElement(View, { style: { flexDirection: 'row', alignItems: 'center' } },
                            React.createElement(View, { style: { marginRight: 16 } },
                                React.createElement(Caption, null, "Surface"),
                                React.createElement(Caption, null, contrastSurface.toFixed(2))),
                            React.createElement(Switch, { trackColor: { false: background, true: primary }, thumbColor: primary, value: useBackground, onValueChange: toggleUseBackground }),
                            React.createElement(View, { style: { marginLeft: 16 } },
                                React.createElement(Caption, null, "Background"),
                                React.createElement(Caption, null,
                                    "1:",
                                    contrastBackground.toFixed(2)))))),
                React.createElement(View, { style: { flexDirection: 'row', marginBottom: 12 } },
                    React.createElement(ColorPreview, { style: {
                            borderColor: 'black',
                            borderWidth: 1,
                            borderStyle: 'dotted',
                        }, color: color, background: useBackground ? background : surface }),
                    React.createElement(IconButton, { icon: contrast < AA_THRESHOLD_CONTRAST
                            ? 'close'
                            : contrast < AAA_THRESHOLD_CONTRAST
                                ? 'check'
                                : 'check-all', onPress: () => showSnackbar(helpText(contrast)) })),
                React.createElement(Button, { mode: "contained", theme: { colors: { primary: color } }, icon: "content-copy", onPress: () => setString(color), style: { marginRight: 'auto' } }, color))),
        React.createElement(Portal, null,
            React.createElement(Snackbar, { style: {}, wrapperStyle: { maxWidth: 720 }, visible: showingSnackbar, onDismiss: () => setShowSnackbar(false) }, snackbarContent.current))));
}
function helpText(contrast) {
    if (contrast < AA_THRESHOLD_CONTRAST) {
        return `Contrast too low: The contrast between the colors (${contrast.toFixed(2)}) is less than 1:${AA_THRESHOLD_CONTRAST}. \n\nThere is empirical evidence that visual acuity of 20/40 (~15% of all people) is associated with a contrast sensitivity loss of roughly 1.5, which is where the 4.5 score is derived.`;
    }
    if (contrast < AAA_THRESHOLD_CONTRAST) {
        return `There is enough contrast between the colors to be legible by almost all people. (Rating: AA)`;
    }
    return `The contrast between the colors is high enough to achieve legibility for people with low 20/80 vision. This visual acuity score is often not able to be corrected with glasses, etc. (Rating: AAA)`;
}
