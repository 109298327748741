import { useBlockNavigation } from '@introcloud/blocks-interface';
import { LinearGradient } from 'expo-linear-gradient';
import { t } from 'i18n-js';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Caption, Card, Paragraph, useTheme } from 'react-native-paper';
import { OutlinedButton } from './Button';
import { DirectionsMenu } from './DirectionsMenu';
import { LocationIcon } from './LocationIcon';
import { useLocale } from './useLocale';
import { mergeTranslations } from './utils';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                directions: 'Directions',
                visit: 'Visit',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                directions: 'Plan route',
                visit: 'Bezoek',
            },
        },
    },
});
export function LocalLocationCard({ id, title, description, address, coordinates, url, tags, illustration, loading, tagsEnabled, style, directionsStyle, }) {
    const { dark, colors: { surface, text }, roundness, } = useTheme();
    // Update content when locale changes
    useLocale();
    const { gotoLocation, gotoExternal } = useBlockNavigation();
    const renderLeft = useCallback((props) => (React.createElement(LocationIcon, Object.assign({ loading: loading, tags: tags, illustration: illustration, fallback: !tagsEnabled, color: text, style: styles.icon }, props))), [illustration, loading, tags]);
    const addressDescription = (address === null || address === void 0 ? void 0 : address.street)
        ? `${address.street} ${address.number || ''}${address.addition || ''}`.trim()
        : '';
    const [latitude, longitude] = coordinates || [];
    const directableLocation = addressDescription
        ? `${title}, ${addressDescription} ${address === null || address === void 0 ? void 0 : address.city}`
        : latitude && longitude
            ? { latitude, longitude }
            : `${title}`;
    return (React.createElement(Card, { style: [
            styles.card,
            {
                borderRadius: roundness,
            },
            style,
        ], onPress: () => gotoLocation(id) },
        React.createElement(Card.Title, { title: title, titleStyle: styles.title, subtitle: React.createElement(Caption, { style: styles.description }, addressDescription ||
                (latitude && longitude
                    ? `latitude: ${latitude}, longitude: ${longitude}`
                    : '')), left: renderLeft }),
        React.createElement(Card.Content, null,
            React.createElement(Paragraph, { numberOfLines: 3 }, description)),
        React.createElement(LinearGradient, { style: [
                styles.gradientOverlay,
                {
                    borderRadius: roundness,
                },
            ], pointerEvents: "none", colors: [
                dark ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)',
                surface,
                surface,
            ] }),
        React.createElement(Card.Actions, { style: styles.actions },
            React.createElement(DirectionsMenu, { icon: "directions", location: directableLocation, label: t('blocks.actions.directions'), style: directionsStyle }),
            url ? (React.createElement(OutlinedButton, { icon: "web", style: styles.urlAction, onPress: () => gotoExternal(url) }, t('blocks.actions.visit'))) : null)));
}
const styles = StyleSheet.create({
    card: { elevation: 1 },
    title: { marginBottom: -4 },
    description: { marginTop: 0 },
    icon: { marginLeft: 0, marginRight: 0, marginVertical: 0 },
    gradientOverlay: {
        position: 'absolute',
        top: 72,
        left: 0,
        width: '100%',
        bottom: 0,
        overflow: 'hidden',
    },
    actions: { padding: 16, paddingTop: 8 },
    urlAction: { marginLeft: 8 },
});
