import { useBlockNavigation } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { Fragment } from 'react';
import { Linking } from 'react-native';
import { Menu } from 'react-native-paper';
import { mergeTranslations } from './utils';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                call: 'Call {{name}}',
                sms: 'Text {{name}}',
                whatsapp: 'Whatsapp {{name}}',
                chat: 'Chat with {{name}}',
            },
            contact: {
                no_phone: 'No phone number',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                call: 'Bel {{name}}',
                sms: 'SMS {{name}}',
                whatsapp: 'Whatsapp {{name}}',
                chat: 'Chat met {{name}}',
            },
            contact: {
                no_phone: 'Geen telefoonnummer',
            },
        },
    },
});
export function ContactMenu({ name, phone, anchor, active, id, onDismiss, showChat, }) {
    const { gotoChat } = useBlockNavigation();
    const isProbablyMobilePhone = phone &&
        (/^(00|\+)310?6/.test(phone) || // Is a Dutch mobile phone
            !/^(00|\+)/.test(phone) || // Doesn't have a country code
            /^(00|\+)([012456789]|3[023456789])/.test(phone)); // Is not a Dutch phone
    return (React.createElement(Menu, { visible: active, anchor: anchor, onDismiss: onDismiss },
        phone ? (React.createElement(Fragment, null,
            React.createElement(Menu.Item, { title: t('blocks.actions.call', { name }), onPress: () => callPhone(phone), icon: "phone" }),
            isProbablyMobilePhone ? (React.createElement(Fragment, null,
                React.createElement(Menu.Item, { title: t('blocks.actions.sms', { name }), onPress: () => textPhone(phone), icon: "message-text" }),
                React.createElement(Menu.Item, { title: t('blocks.actions.whatsapp', { name }), onPress: () => whatsAppPhone(phone), icon: "whatsapp" }))) : null)) : (React.createElement(Menu.Item, { title: t('blocks.contact.no_phone') })),
        showChat ? (React.createElement(Menu.Item, { title: t('blocks.actions.chat', { name }), onPress: () => {
                onDismiss();
                gotoChat(`:user/${JSON.stringify({ user: id })}`);
            }, icon: "forum" })) : null));
}
export function callPhone(phone) {
    const sanitized = phone.replace(/[^+0-9]/g, '');
    return Linking.openURL(`tel:${sanitized}`);
}
export function textPhone(phone) {
    const sanitized = phone.replace(/[^+0-9]/g, '');
    return Linking.openURL(`sms:${sanitized}`);
}
export function whatsAppPhone(phone) {
    const sanitized = phone.replace(/[^+0-9]/g, '');
    return Linking.openURL(`https://wa.me/${encodeURIComponent(sanitized.replace(/^00/, '+'))}`);
}
