import { useBlockNavigation } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Divider, IconButton, Menu } from 'react-native-paper';
import { PrimaryButton } from './Button';
import { useLocalization } from './useLocale';
import { mergeTranslations } from './utils';
mergeTranslations({
    en: {
        blocks: {
            directions: {
                label: 'Directions',
            },
            actions: {
                walking: 'Walking',
                biking: 'Biking',
                transit: 'Public Transit',
                maps: 'See in maps',
            },
        },
    },
    nl: {
        blocks: {
            directions: {
                label: 'Routebeschrijving',
            },
            actions: {
                walking: 'Lopen',
                biking: 'Fietsen',
                transit: 'Openbaar vervoer',
                maps: 'Bekijk in maps',
            },
        },
    },
});
export function DirectionsMenu({ label, labelLocalized, icon, location, style, iconOnly, }) {
    const finalLabel = useLocalization(labelLocalized, label || t('blocks.directions.label'));
    const { gotoDirections } = useBlockNavigation();
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((prev) => !prev), [setActive]);
    const gotoBike = useCallback(() => gotoDirections(location, 'bicycling'), [gotoDirections, location]);
    const gotoTransit = useCallback(() => gotoDirections(location, 'transit'), [gotoDirections, location]);
    const gotoWalk = useCallback(() => gotoDirections(location, 'walking'), [gotoDirections, location]);
    const openInMaps = useCallback(() => gotoDirections(location, 'none'), [gotoDirections, location]);
    return (React.createElement(Menu, { anchor: (finalLabel && !iconOnly) || !icon ? (React.createElement(PrimaryButton, { icon: icon, onPress: toggleActive, style: style }, finalLabel || t('blocks.directions.label'))) : (React.createElement(IconButton, { style: { marginRight: 16 }, icon: icon, onPress: toggleActive, accessibilityLabel: t('blocks.directions.label') })), visible: active, onDismiss: toggleActive },
        React.createElement(Menu.Item, { onPress: gotoWalk, title: t('blocks.actions.walking'), icon: "walk" }),
        React.createElement(Menu.Item, { onPress: gotoBike, title: t('blocks.actions.biking'), icon: "bike" }),
        React.createElement(Menu.Item, { onPress: gotoTransit, title: t('blocks.actions.transit'), icon: "subway-variant" }),
        React.createElement(Divider, { style: { height: StyleSheet.hairlineWidth } }),
        React.createElement(Menu.Item, { onPress: openInMaps, title: t('blocks.actions.maps'), icon: "map-marker" })));
}
