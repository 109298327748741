import { __rest } from "tslib";
import I18n, { t } from 'i18n-js';
import React, { Fragment } from 'react';
import { StyleSheet, View, } from 'react-native';
import { Card, Headline, IconButton, Paragraph, Surface, Title, useTheme, } from 'react-native-paper';
import { useLocaleAction, useLocalization } from './useLocale';
import { mergeTranslations, rgba } from './utils';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                translate: 'Translate content',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                translate: 'Vertaal tekst',
            },
        },
    },
});
/** @deprecated {useLocalization} */
export function useTranslation(original, translation) {
    const [useTranslation, translate] = useTranslationAction();
    if (!original) {
        return [translation || '', translate];
    }
    if (!translation) {
        return [original, translate];
    }
    return [
        useTranslation ? translation || original : original || translation,
        translate,
    ];
}
/** @deprecated {useLocale}, {useLocaleAction} */
export function useTranslationAction() {
    const [locale, translate] = useLocaleAction();
    return [locale !== I18n.defaultLocale, translate];
}
export function TextBlock(_a) {
    var { translation, localizedText, textLocalized = localizedText, options } = _a, others = __rest(_a, ["translation", "localizedText", "textLocalized", "options"]);
    if (textLocalized) {
        return (React.createElement(LocalizedTextBlock, Object.assign({ textLocalized: textLocalized, options: options }, others)));
    }
    if (translation || (options === null || options === void 0 ? void 0 : options.translatable)) {
        return (React.createElement(LegacyTranslationTextBlock, Object.assign({ translation: translation, options: options }, others)));
    }
    return React.createElement(TextTextBlock, Object.assign({ options: options }, others));
}
function LocalizedTextBlock({ options, text, textLocalized, style, round, textStyle, renderFallback, }) {
    const value = useLocalization(textLocalized, text);
    const { roundness } = useTheme();
    if (!value) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content },
                React.createElement(TextContent, { content: value, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })))));
}
function LegacyTranslationTextBlock({ options, text, translation, style, round, textStyle, renderFallback, }) {
    const [value, translate] = useTranslation(text, translation);
    const { roundness } = useTheme();
    if (!value) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content, accessibilityLiveRegion: !(options === null || options === void 0 ? void 0 : options.hideTranslatable) && translation ? 'polite' : undefined },
                React.createElement(TextContent, { content: value, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })),
            !!translation && !(options === null || options === void 0 ? void 0 : options.hideTranslatable) ? (React.createElement(IconButton, { accessibilityLabel: t('blocks.actions.translate'), icon: "translate", onPress: translate, style: styles.button, disabled: options && options.translatable === false })) : null)));
}
function TextTextBlock({ options, text, style, round, textStyle, renderFallback, }) {
    const { roundness } = useTheme();
    if (!text) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content },
                React.createElement(TextContent, { content: text, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })))));
}
function TextContent({ content, size, style, }) {
    switch (size || 'paragraph') {
        case 'paragraph': {
            return (React.createElement(Fragment, null, content.split('\n').map((paragraph, index) => (React.createElement(Paragraph, { key: index, style: [styles.paragraph, style] }, paragraph)))));
        }
        case 'title': {
            return React.createElement(Title, { style: [styles.title, style] }, content);
        }
        case 'headline': {
            return React.createElement(Headline, { style: [styles.title, style] }, content);
        }
    }
}
const styles = StyleSheet.create({
    title: { margin: 0 },
    wrapper: { elevation: 1, paddingBottom: 4, paddingTop: 12 },
    contentWrapper: { flexDirection: 'row', width: '100%' },
    content: { flex: 1 },
    paragraph: { marginBottom: 10, lineHeight: 22 },
    dragging: {
        elevation: 8,
    },
    editing: {
        borderWidth: 2,
        borderColor: rgba([255, 127, 127, 0.3]),
    },
    button: { marginHorizontal: 12, marginVertical: 0 },
});
