import { useDestination, useWindowWidth } from '@introcloud/blocks-interface';
import React, { useMemo } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Caption, Card, IconButton } from 'react-native-paper';
import { useLocalization } from './useLocale';
function sizeToColumns(size) {
    switch (size) {
        case 'large': {
            return 2;
        }
        case 'small': {
            return 3;
        }
        case 'tiny': {
            return 4;
        }
        default: {
            return 3;
        }
    }
}
export function IconGrid({ actions, size }) {
    const windowWidth = useWindowWidth();
    const columns = sizeToColumns(size);
    const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);
    const empty = useMemo(() => {
        const result = [];
        const remainder = columns - ((actions.length % columns) % columns);
        if (remainder === columns) {
            return result;
        }
        for (let i = 0; i < remainder; i++) {
            result.push(Math.random().toString(36));
        }
        return result;
    }, [actions.length, columns]);
    return (React.createElement(View, { style: styles.iconGrid },
        actions.map((action, index) => {
            return React.createElement(PureIconGridItem, { action: action, key: index, size: width });
        }),
        empty.map((none) => (React.createElement(View, { style: { width, height: width }, key: none })))));
}
export function IconCard({ size, action }) {
    const onPress = useDestination(action.destination);
    const hasAction = Boolean(action.destination.value);
    const finalAccessibilityLabel = useLocalization(action.accessibilityLabelLocalized, action.accessibilityLabel);
    const finalLabel = useLocalization(action.labelLocalized, action.label);
    return (React.createElement(Card, { style: [
            styles.iconCard,
            hasAction && Platform.OS === 'web'
                ? { cursor: 'pointer' }
                : {},
            {
                width: size,
                height: size,
            },
        ], onPress: hasAction ? onPress : undefined },
        React.createElement(View, { style: styles.iconInner, pointerEvents: "none" },
            React.createElement(IconButton, { icon: action.icon || 'link-variant', size: 48, onPress: hasAction ? onPress : undefined, accessible: Boolean(finalAccessibilityLabel), accessibilityLabel: finalAccessibilityLabel || undefined }),
            React.createElement(Caption, { style: styles.iconCaption }, finalLabel))));
}
const PureIconGridItem = React.memo(IconCard);
const styles = StyleSheet.create({
    iconCard: {
        margin: 0,
        marginBottom: 16,
    },
    iconCaption: {
        marginTop: -14,
        textAlign: 'center',
        lineHeight: 12,
        maxWidth: '100%',
    },
    iconInner: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        paddingBottom: 16,
    },
    iconGrid: {
        flexWrap: 'wrap',
        paddingHorizontal: 8,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
    },
});
