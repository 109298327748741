import { useBlockData, useBlockDataGameMap, useDimensions, } from '@introcloud/blocks-interface';
import { createElement, Fragment, useCallback, useMemo, } from 'react';
import { Image, View, } from 'react-native';
import { ActivityIndicator, Card } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
const DEFAULT_STYLE = {
    elevation: 1,
};
export function GameMapBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { id }, } = block;
    const { error, data, loading } = useBlockDataGameMap(id);
    if (!id) {
        return renderFallback();
    }
    if (loading) {
        return createElement(Card, {
            elevation: 1,
            style: {
                width: '100%',
                minHeight: 320,
                justifyContent: 'center',
                alignItems: 'center',
            },
            children: createElement(ActivityIndicator, { size: 'large' }),
        });
    }
    if (error || !data) {
        return renderFallback();
    }
    return createElement(GameMapPreview, {
        data,
    });
}
function GameMapPreview({ data }) {
    return createElement(GameMapViewPortPreview, {
        shape: data.shape,
        initialCenter: data.guest.start,
        gameMap: data,
    });
}
function GameMapViewPortPreview({ shape, initialCenter, gameMap, }) {
    const places = useMemo(() => gameMap.mapPlaceRef.map((placeRef) => placeRef), [gameMap.mapPlaceRef]);
    const baseSize = 1440;
    const displayRatio = 1;
    const displaySize = baseSize * displayRatio;
    const ratio = (baseSize / Math.min(shape.height, shape.width)) * displayRatio;
    const { width: windowWidth, height: windowHeight } = useDimensions();
    const mapRatio = shape.width / shape.height;
    const cameraPosition = useMemo(() => {
        return initialCenter;
    }, 
    // This memoisation takes care of generating a semi-stable value for the
    // viewport center that can be passed around later. It doesn't matter if the
    // initial values update; as this map is already rendered and the user is
    // already placed.
    //
    [] /* purposefully empty */);
    const viewport = useMemo(() => ({
        displayHeight: ratio * shape.height,
        displayWidth: ratio * shape.width,
        center: cameraPosition,
        ratio,
        shape: {
            top: 0,
            left: 0,
            right: shape.width - 1,
            bottom: shape.height - 1,
            ratio: mapRatio,
        },
        displayFromCoords(x, y) {
            return { left: x * ratio, top: y * ratio };
        },
        translateCoord(coord) {
            return coord * ratio;
        },
        displayFromSize(width, height) {
            return { width: width * ratio, height: height * ratio };
        },
    }), 
    // X and Y are not coordinates but relatively stable values. This means that
    // this value will not update when the actual center x and y update, but
    // rather when the wrapper object (Animated.Value) that holds them does.
    [displaySize, ratio, mapRatio, cameraPosition, shape]);
    // This is the size of the viewport, aka what can be seen on the screen. The
    // origin (0, 0) is the top-left of the viewport, not the map
    const viewportShape = useMemo(() => {
        const viewportWidth = Math.min(720, windowWidth * 1.0);
        const viewportHeight = Math.min(720, windowHeight * 0.8);
        return {
            width: Math.round(viewportWidth / ratio),
            height: Math.round(viewportHeight / ratio),
        };
    }, [ratio, windowWidth, windowHeight]);
    // These are the coordinates of the map center, with the origin (0,0) in the
    // top-left corner.
    /*
    const mapCenterCoords = useMemo(() => {
      return {
        x: shape.width / 2,
        y: shape.height / 2,
      };
    }, [shape.width, shape.height]);
    */
    // This is the actual transformation of pixels where it centers the
    // coordinates viewport.center in the middle of the viewport itself.
    //
    // The min and max calculations are done so that the map edge is flush with
    // the viewport edge when the viewport.center is at one or two of the edges.
    //
    const transform = useMemo(() => [
        {
            translateX: Math.min(0, Math.max(0 - viewport.translateCoord(shape.width - viewportShape.width), -ratio / 2 -
                viewport.translateCoord(viewport.center.x - viewportShape.width / 2))),
        },
        {
            translateY: Math.min(0, Math.max(0 - viewport.translateCoord(shape.height - viewportShape.height), -ratio / 2, -viewport.translateCoord(viewport.center.y - viewportShape.height / 2))),
        },
    ], [viewport, viewportShape, shape, ratio]);
    return createElement(Card, {
        nativeID: 'map-viewport',
        style: Object.assign({ maxWidth: 720, width: windowWidth * 1.0, maxHeight: 720, height: windowHeight * 0.8, overflow: 'hidden', backgroundColor: '#222' }, (windowWidth <= 736 ? { borderRadius: 0 } : {})),
        children: createElement(View, { style: { transform, width: '100%', height: '100%' } }, [
            createElement(GameMapBackgroundPreview, {
                key: 'background',
                image: gameMap.image.background,
                viewport,
                transform,
            }),
            createElement(GameMapPlacesPreview, {
                items: places,
                key: 'map-places',
                viewport: Object.assign(Object.assign({}, viewport), { transform }),
            }),
        ]),
    });
}
function GameMapBackgroundPreview({ image, transform, viewport, }) {
    const { getImageUrl } = useBlockData();
    const source = getImageUrl(image, 'icon_1440') || '';
    const { displayHeight, displayWidth, shape: { ratio: imageRatio }, } = viewport;
    if (!source) {
        return null;
    }
    return createElement(View, { style: { transform } }, createElement(Image, {
        style: {
            width: displayWidth,
            height: displayHeight,
        },
        source: { uri: source, width: imageRatio * 1440, height: 1440 },
    }));
}
function GameMapPlacesPreview({ items, viewport, }) {
    return createElement(Fragment, {}, items.map((item) => createElement(GameMapPlacePreview, Object.assign(Object.assign({ key: item.mapPlaceId }, item.mapPlace), { viewport }))));
}
export function GameMapPlacePreview({ _id, kind, shape, image, viewport, }) {
    switch (kind) {
        case 'blocked': {
            return createElement(BlockedPlace, Object.assign(Object.assign({}, shape), { viewport }));
        }
        case 'place': {
            return createElement(VisitablePlace, Object.assign(Object.assign({}, shape), { _id, image, viewport }));
        }
        case 'background': {
            return createElement(BackgroundPlace, Object.assign(Object.assign({}, shape), { image, viewport }));
        }
        default: {
            return null;
        }
    }
}
function BlockedPlace({ x, y, width, height, viewport, }) {
    return null;
}
function BackgroundPlace({ x, y, width, height, image, viewport, }) {
    const { displayFromCoords, displayFromSize, transform } = viewport;
    const { getImageUrl } = useBlockData();
    const imageRatio = Math.round(width / height);
    return createElement(View, {
        pointerEvents: 'none',
        style: Object.assign(Object.assign(Object.assign({ position: 'absolute' }, displayFromCoords(x, y)), displayFromSize(width, height)), { backgroundColor: 'transparent', padding: 0, margin: 0, overflow: 'hidden', borderRadius: 0, zIndex: 0, transform }),
    }, image && image.background
        ? createElement(Image, {
            style: displayFromSize(width, height),
            source: {
                width: imageRatio * 1440,
                height: 1440,
                uri: getImageUrl(image.background, 'icon_1440'),
            },
        })
        : null);
}
function VisitablePlace({ _id, x, y, width, height, image, viewport, }) {
    const { getImageUrl } = useBlockData();
    const { displayFromCoords, displayFromSize, transform } = viewport;
    const imageRatio = Math.round(width / height);
    return createElement(View, {
        style: Object.assign(Object.assign(Object.assign({ position: 'absolute' }, displayFromCoords(x, y)), displayFromSize(width, height)), { transform, overflow: 'hidden', zIndex: 1 }),
    }, createElement(Card, {
        elevation: 0,
        style: {
            elevation: 0,
            padding: 0,
            margin: 0,
            overflow: 'hidden',
            borderRadius: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
        },
        children: image && image.background
            ? createElement(Image, Object.assign(Object.assign({}, displayFromSize(width, height)), { source: {
                    width: imageRatio * 1440,
                    height: 1440,
                    uri: getImageUrl(image.background, 'icon_1440'),
                } }))
            : null,
    }));
}
