import { PrimaryButton, Spacer, useLocalization } from '@introcloud/blocks';
import { createElement, Fragment } from 'react';
import { Surface, Text } from 'react-native-paper';
const DEFAULT_STYLE = {
    elevation: 1,
};
export function SwitcherBlock(block) {
    var _a, _b;
    const style = DEFAULT_STYLE;
    const finalLabel = useLocalization((_a = block.value) === null || _a === void 0 ? void 0 : _a.labelLocalized, ((_b = block.value) === null || _b === void 0 ? void 0 : _b.label) || 'Add session');
    // TODO suspend
    return createElement(Fragment, undefined, [
        createElement(Surface, {
            key: 'surface',
            style,
            children: createElement(Text, {}, `[block placeholder] ${block.kind}`),
        }),
        createElement(Spacer, { space: 1, key: 'spacer' }),
        createElement(PrimaryButton, {
            key: 'action',
            onPress: () => { },
            children: finalLabel,
        }),
    ]);
}
