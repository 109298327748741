var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { makeTabIcon } from '@introcloud/tab-icon';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import color from 'color';
import React, { createContext, useContext, useMemo } from 'react';
import { useTheme } from 'react-native-paper';
import { AA_THRESHOLD_CONTRAST, useContrast } from 'use-color-luminance';
const Tab = createMaterialBottomTabNavigator();
export default function Tabs(_a) {
    var { componentFor, neutralBackground = true, accentFallback = neutralBackground, companyName, locale } = _a, props = __rest(_a, ["componentFor", "neutralBackground", "accentFallback", "companyName", "locale"]);
    const theme = useTheme();
    const tabs = useTabs(locale);
    // The tab bar background is considered dark if a neutral color is chosen,
    // because then it will use the surface color of the dark theme, or if the
    // contrast with a light color (white) is high. In that last case, a dark
    // background means that light text will do well.
    const backgroundColor = neutralBackground
        ? theme.colors.surface
        : theme.colors.primary;
    const needLightContent = neutralBackground
        ? theme.dark
        : color(theme.colors.primary).isDark();
    // Calculate if we can use the primary on top of the background. This is only
    // true if there is enough contrast between that primary color and the
    // background.
    const primaryContrastsWithBackground = useContrast(theme.colors.primary, backgroundColor) >= AA_THRESHOLD_CONTRAST;
    // Calculate if we can use the accent on top of the background. This is only
    // true if there is enough contrast between that primary color and the
    // background.
    const accentContrastsWithBackground = useContrast(theme.colors.accent, backgroundColor) >= AA_THRESHOLD_CONTRAST;
    const inactiveColor = needLightContent ? '#FFFFFFAA' : '#00000055';
    const activeColor = [
        primaryContrastsWithBackground && theme.colors.primary,
        accentContrastsWithBackground && accentFallback && theme.colors.accent,
        needLightContent ? '#FFFFFF' : '#111111',
    ]
        .filter(Boolean)
        .shift();
    return (React.createElement(Tab.Navigator, Object.assign({ shifting: true, sceneAnimationEnabled: false, activeColor: activeColor, inactiveColor: inactiveColor }, props), tabs.map(({ name, icon: TabBarIcon, title, color: tabBarColor }) => {
        return (React.createElement(Tab.Screen, { key: name, name: name, component: componentFor(name), options: {
                tabBarIcon: (props) => React.createElement(TabBarIcon, Object.assign({}, props)),
                tabBarColor: tabBarColor || backgroundColor,
                tabBarLabel: title,
                title: `${title} · ${companyName}`,
            } }));
    })));
}
export const TAB_TO_NAME_MAPPING = {
    home: 'Home',
    chats: 'Chats',
    custom: 'Custom',
    custom2: 'Custom2',
    events: 'Events',
    'event-days': 'EventDays',
    'game-map': 'GameMap',
    goals: 'Goals',
    information: 'Information',
    information2: 'Information2',
    information3: 'Information3',
    information4: 'Information4',
    information5: 'Information5',
    live: 'Live',
    locations: 'Locations',
    matching: 'Matching',
    news: 'News',
    profile: 'Profile',
    scanner: 'Scanner',
    payment: 'Payment',
    incorrect: 'Incorrect',
};
export function tabName(tab) {
    const name = TAB_TO_NAME_MAPPING[tab];
    return name || (__DEV__ ? 'Incorrect' : undefined);
}
export const DEFAULT_TABS = [
    {
        tab: 'home',
        title: 'Home',
        titleLocalized: { en: 'Home', nl: 'Home' },
        icon: {
            family: 'MaterialCommunityIcons',
            name: 'home',
        },
    },
    {
        tab: 'events',
        title: 'Events',
        titleLocalized: { en: 'Events', nl: 'Events' },
        icon: {
            family: 'MaterialCommunityIcons',
            name: 'calendar',
        },
    },
    {
        tab: 'locations',
        title: 'Map',
        titleLocalized: { en: 'Map', nl: 'Kaart' },
        icon: {
            family: 'MaterialCommunityIcons',
            name: 'map',
        },
    },
    {
        tab: 'information',
        title: 'Info',
        titleLocalized: { en: 'Info', nl: 'Info' },
        icon: {
            family: 'MaterialCommunityIcons',
            name: 'information',
        },
    },
    {
        tab: 'chats',
        title: 'Chats',
        titleLocalized: { en: 'Chats', nl: 'Chats' },
        icon: {
            family: 'MaterialCommunityIcons',
            name: 'forum',
        },
    },
];
const RemoteTabsContext = createContext(DEFAULT_TABS);
export const RemoteTabsProvider = RemoteTabsContext.Provider;
export function useTabs(locale) {
    const remoteTabs = useContext(RemoteTabsContext);
    return useMemo(() => {
        const tabs = remoteTabs.map(({ tab, icon, title, localizedTitle = {}, titleLocalized = localizedTitle, color, }) => {
            const name = tabName(tab);
            if (!name) {
                return null;
            }
            const tabConfiguration = {
                name,
                icon: makeTabIcon(icon),
                title: titleLocalized ? titleLocalized[locale] || title : title,
                color,
            };
            return tabConfiguration;
        });
        return tabs.filter(Boolean);
    }, [remoteTabs, locale]);
}
