import { StoredMemoryValue, useMemoryValue, useMutableMemoryValue, } from 'expo-use-memory-value';
import I18n from 'i18n-js';
import { useCallback } from 'react';
export const CURRENT_LOCALE = new StoredMemoryValue('blocks.settings.locale', false, I18n.defaultLocale);
const KNOWN_LANGUAGES = ['en', 'nl'];
const DEFAULT_LANGUAGE = 'en';
CURRENT_LOCALE.subscribe((value) => {
    var _a;
    if (value === null) {
        I18n.locale = I18n.defaultLocale;
    }
    else {
        I18n.locale =
            (_a = KNOWN_LANGUAGES.find((known) => known === value)) !== null && _a !== void 0 ? _a : DEFAULT_LANGUAGE;
    }
});
CURRENT_LOCALE.hydrate();
export function useLocale() {
    return useMemoryValue(CURRENT_LOCALE) || I18n.defaultLocale;
}
export function useLocaleAction() {
    const [locale, setLocale] = useMutableMemoryValue(CURRENT_LOCALE);
    const translate = useCallback((next = null) => {
        if (next) {
            setLocale(next);
            return;
        }
        setLocale((prev) => {
            if (!prev) {
                prev = I18n.defaultLocale;
            }
            const index = KNOWN_LANGUAGES.indexOf(prev);
            return KNOWN_LANGUAGES[index + 1] || KNOWN_LANGUAGES[0];
        });
    }, [setLocale]);
    return [locale || I18n.defaultLocale, translate];
}
export function useLocalization(textLocalized, fallback) {
    const locale = useLocale();
    return localize(textLocalized, fallback, locale);
}
export function localize(textLocalized, fallback, locale = I18n.defaultLocale) {
    if (!textLocalized) {
        return fallback;
    }
    return textLocalized[locale] || fallback;
}
