import React, { useMemo, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { DataTable, Surface, Text, useTheme } from 'react-native-paper';
import { localize, useLocale } from './useLocale';
export function Table({ round, headers, headersLocalized, values, valuesLocalized, columns, renderFallback, }) {
    const { roundness } = useTheme();
    const locale = useLocale();
    const finalHeaders = useMemo(() => headers.map((fallback, index) => localize(headersLocalized ? headersLocalized[index] : undefined, fallback, locale)), [locale, headersLocalized, headers]);
    const finalValues = useMemo(() => values.map((fallbackRow, rowIndex) => fallbackRow.map((cell, index) => localize(valuesLocalized
        ? (valuesLocalized[rowIndex] || [])[index]
        : undefined, cell, locale))), [locale, valuesLocalized, values]);
    const iterator = useMemo(() => {
        const result = [];
        for (let i = 0; i < columns; i++) {
            result.push(i);
        }
        return result;
    }, [columns]);
    const [sortColumn, setSortColumn] = useState(-1);
    const [sortDirection, setSortDirection] = useState('ascending');
    const sortedValues = useMemo(() => {
        if (sortColumn === -1) {
            return finalValues;
        }
        return finalValues
            .slice()
            .sort(sortDirection === 'ascending'
            ? (a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || '')
            : (b, a) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
    }, [finalValues, sortColumn, sortDirection]);
    if (finalValues.length === 0 || columns === 0) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const topBorderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        overflow: 'hidden',
    };
    const bottomBorderStyle = {
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [topBorderStyle, bottomBorderStyle, styles.surface] },
        React.createElement(DataTable, { style: [
                topBorderStyle,
                bottomBorderStyle,
                {
                    overflow: 'hidden',
                },
            ] },
            React.createElement(DataTable.Header, { style: [topBorderStyle, { overflow: 'hidden' }] }, iterator.map((i) => (React.createElement(DataTable.Title, { key: i, style: [
                    { flex: i === 0 ? 3 : 1 },
                    Platform.OS === 'web' ? { cursor: 'pointer' } : {},
                ], numberOfLines: 3, sortDirection: sortColumn === i ? sortDirection : undefined, onPress: () => {
                    if (sortColumn === i) {
                        if (sortDirection === 'descending') {
                            setSortColumn(-1);
                        }
                        else {
                            setSortDirection('descending');
                        }
                    }
                    else {
                        setSortColumn(i);
                        setSortDirection('ascending');
                    }
                } }, finalHeaders[i] || '')))),
            sortedValues.map((row, index, self) => (React.createElement(DataTable.Row, { key: index, style: [
                    { paddingVertical: 12 },
                    index === self.length - 1
                        ? [
                            bottomBorderStyle,
                            {
                                overflow: 'hidden',
                            },
                        ]
                        : undefined,
                    Platform.OS === 'web' ? { cursor: 'auto' } : {},
                ] }, iterator.map((i) => (React.createElement(Text, { key: i, style: { flex: i === 0 ? 3 : 1 } }, row[i])))))))));
}
// TODO: figure out what numeric={i > 0} contributed to the style
const styles = StyleSheet.create({
    surface: { elevation: 1 },
});
