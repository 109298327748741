import { Action, mergeTranslations, useLocale } from '@introcloud/blocks';
import { googleMapsUrl } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import { createElement, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Surface, useTheme } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
import { getChatRoomPlaceHolder } from './ChatRoomBlock';
mergeTranslations({
    en: {
        blocks: {
            actions: {
                generic_chat: 'Chat',
                generic_call: 'Call',
                generic_mail: 'Mail',
                generic_site: 'View site',
                generic_map: 'Show on map',
            },
        },
    },
    nl: {
        blocks: {
            actions: {
                generic_chat: 'Chat',
                generic_call: 'Bel',
                generic_mail: 'Mail',
                generic_site: 'Bekijk site',
                generic_map: 'Bekijk op kaart',
            },
        },
    },
});
export function ContactBlock(block) {
    if (block.value.chat) {
        return createElement(ChattableContactBlock, block);
    }
    return createElement(QuietContactBlock, block);
}
function ChattableContactBlock(block) {
    const timestamp = useInaccurateTimestamp({ every: 10 * 1000 });
    const duration = block.options.duration;
    const currentlyActive = duration &&
        duration.start &&
        duration.end &&
        duration.start < timestamp &&
        duration.end > timestamp;
    return createElement(QuietContactBlock, Object.assign(Object.assign({}, block), { chatActive: !!currentlyActive }));
}
function QuietContactBlock(block) {
    // Update content when locale updates
    useLocale();
    const { roundness } = useTheme();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { 
    // id,
    options: { spacing, round }, value: { chat, website, telephone, email, address }, previous, next, dragging, chatActive, } = block;
    if (!chat && !website && !telephone && !email && !address) {
        return renderFallback();
    }
    const shouldCollapseTop = false && !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const spacingStyle = spacing
        ? {
            paddingTop: shouldCollapseTop ? 0 : spacing * 8 + 8,
            paddingBottom: spacing * 8,
        }
        : undefined;
    const previousHasBottomConnection = extendsFullyToBottom(previous);
    const nextHasTopConnection = extendsFullyToTop(next);
    const borderStyle = round && roundness
        ? {
            borderTopLeftRadius: previousHasBottomConnection ? 0 : roundness,
            borderTopRightRadius: previousHasBottomConnection ? 0 : roundness,
            borderBottomLeftRadius: nextHasTopConnection ? 0 : roundness,
            borderBottomRightRadius: nextHasTopConnection ? 0 : roundness,
        }
        : undefined;
    return createElement(Surface, {
        style: [spacingStyle, borderStyle, styles.surface],
        children: [
            chat
                ? createElement(Action, {
                    key: 'chat',
                    mode: 'primary',
                    icon: 'chat',
                    label: t('blocks.actions.generic_chat'),
                    surface: false,
                    destination: {
                        kind: 'chat',
                        value: getChatRoomPlaceHolder('1-on-1'),
                    },
                    style: styles.action,
                    disabled: !chatActive,
                })
                : null,
            telephone && telephone.trim()
                ? createElement(Action, {
                    key: 'phone',
                    mode: 'primary',
                    icon: 'phone',
                    label: t('blocks.actions.generic_call'),
                    surface: false,
                    destination: {
                        kind: 'external',
                        value: `tel:${encodeURIComponent(telephone)}`,
                    },
                    style: styles.action,
                })
                : null,
            email && email.trim()
                ? createElement(Action, {
                    key: 'email',
                    mode: 'primary',
                    icon: 'email',
                    label: t('blocks.actions.generic_mail'),
                    surface: false,
                    destination: {
                        kind: 'external',
                        value: `mailto:${encodeURIComponent(email)}`,
                    },
                    style: styles.action,
                })
                : null,
            website && website.trim()
                ? createElement(Action, {
                    key: 'website',
                    mode: 'accent',
                    icon: 'link',
                    label: t('blocks.actions.generic_site'),
                    surface: false,
                    destination: { kind: 'external', value: website },
                    style: styles.action,
                })
                : null,
            address && address.trim()
                ? createElement(Action, {
                    key: 'address',
                    mode: 'accent',
                    icon: 'map',
                    label: t('blocks.actions.generic_map'),
                    surface: false,
                    destination: {
                        kind: 'external',
                        value: googleMapsUrl(undefined, address),
                    },
                    style: styles.action,
                })
                : null,
        ].filter(Boolean),
    });
}
const styles = StyleSheet.create({
    surface: {
        paddingHorizontal: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        elevation: 1,
    },
    action: { marginRight: 8, marginBottom: 8 },
});
